.quote-variant-tag {
	padding: 3px 8px;
	border-radius: 6px;
	font-size: var(--text-sm);
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--primary-contrast-color);
	color: var(--button-text-primary-contrast-color);
	min-width: max-content;
}
