.mat-mdc-menu-panel:has(.notification-list__container) {
	min-width: unset;
	max-width: unset;
	border-radius: var(--border-radius);
	margin-top: 5px;
	border: 1px solid var(--divider-color);
}

.mat-mdc-menu-panel:has(.notification-list__container) .mat-mdc-menu-content {
	padding: 0;
}
