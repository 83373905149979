/* grid-template-columns will be set by descendants. */
.dialog-form__row {
	display: grid;
	gap: 10px;
	padding-inline: 10px;
}

.dialog-form__row_greyed {
	background-color: var(--background-color);
	border-bottom: 1px solid var(--input-border-color);
}

.dialog-form__row_light {
	background-color: var(--background-light-color);
}

.dialog-form__row_greyed:first-child {
	border-top: 1px solid var(--input-border-color);
}

.dialog-form__cell {
	margin-block: 10px;
	display: flex;
	align-items: center;
	overflow: hidden;
}

.dialog-form__header-row .dialog-form__cell {
	min-width: min-content;
}

.dialog-form__cell_right {
	justify-content: flex-end;
}

.dialog-form__cell_center {
	justify-content: center;
}

.cursor {
	cursor: pointer;
}
