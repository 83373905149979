h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}

.page-title {
	font-size: var(--text-xl);
	font-weight: 500;
}

.header {
	display: flex;
	justify-content: space-between;
}
