@import "./variables.css";
@import "./material/index.css";
@import "./elements/index.css";

* {
	box-sizing: border-box;
	font-family: "Roboto", sans-serif;
}

html,
body {
	min-height: 100%;
	background-color: var(--background-color);
	color: var(--background-contrast-color);
	margin: 0;
}

/* Global animations */
@keyframes fadein {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
