.ql-toolbar.ql-snow:has(.ql-formats) {
	border: none;
	border-bottom: 1px solid var(--divider-color);
	background-color: var(--background-color);
	padding-left: 22px;
}

.ql-snow.ql-toolbar .ql-formats button.ql-active,
.ql-snow.ql-toolbar .ql-formats .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-formats .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-formats button:hover,
.ql-snow.ql-toolbar .ql-formats .ql-picker-item:hover {
	color: var(--primary-color);
}

.ql-snow.ql-toolbar .ql-formats .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-formats button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-formats .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-formats button:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-formats .ql-picker-item:hover .ql-stroke {
	stroke: var(--primary-color);
}
