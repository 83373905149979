.ql-container.ql-snow:has(.ql-editor) {
	border: none;
}

.ql-container.ql-snow:has(.ql-editor) .ql-editor {
	padding: 20px 30px;
	font-size: 1rem;
}

.ql-container.ql-snow:has(.ql-editor) .ql-editor::before {
	left: 30px;
}

.ql-container.ngx-quill-view-html:has(.ql-editor) .ql-editor ul {
	padding-left: 1.5rem;
}

.ql-container.ngx-quill-view-html:has(.ql-editor) .ql-editor li {
	list-style-type: unset;
}

/* Customize styles for specific components. */
.content .ql-container.ql-snow:has(.ql-editor) .ql-editor {
	padding: 0;
}
