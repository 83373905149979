/* Custom component styles. */
.quote-table .mat-sort-header-container {
	justify-content: center;
}

.mdc-data-table__cell,
.mdc-data-table__header-cell {
	/* Disable to override material styles. */
	/* stylelint-disable-next-line declaration-no-important */
	padding-block: 8px !important;
}
