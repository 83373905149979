.typography-bold {
	font-weight: 500;
}

.typography-error {
	color: var(--error-color);
}

.typography-button-link {
	color: var(--button-text-primary-contrast-color);
	text-decoration: underline;
	cursor: pointer;
}
