.meic-skeleton {
	border-radius: 3px;
	background: linear-gradient(-45deg, #e7e7e7, #dddddd, #c9c7c7, #c9c7c7);
	background-size: 400% 400%;
	animation: skeleton-gradient-move 3s ease-in-out infinite;
}

@keyframes skeleton-gradient-move {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

h1.meic-skeleton::before,
h2.meic-skeleton::before,
h3.meic-skeleton::before,
h4.meic-skeleton::before,
h5.meic-skeleton::before,
h6.meic-skeleton::before,
p.meic-skeleton::before,
span.meic-skeleton::before,
time.meic-skeleton::before,
a.meic-skeleton::before {
	/* To emulate height of text. */
	content: ".";
	display: inline-block;
	color: transparent;
}
