.custom-dialog > mat-dialog-container {
	--mdc-dialog-container-color: transparent;
	--mdc-dialog-container-shape: 20px;
}

.dialog__actions {
	display: flex;
	justify-content: flex-end;
	gap: 15px;
	margin-top: 20px;
	width: 100%;
}

.dialog__actions-reverse {
	flex-direction: row-reverse;
	justify-content: flex-start;
}

.dialog__message {
	background-color: var(--background-contrast-color);
	color: var(--text-contrast-color);
	border-radius: var(--border-radius);
	max-width: 300px;
	padding: 5px;
	font-size: 0.9rem;
	margin-bottom: 5px;
}

.dialog__message_center {
	text-align: center;
}
