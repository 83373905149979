:root {
	/*
    Please use the following template to add new variables, dark/light valuations are optional.

    --<name>-color: <color>;
    --<name>-contrast-color: <contrast-color>;
    --<name>-dark-color: <dark-color>;
    --<name>-dark-contrast-color: <dark-contrast-color>;
    --<name>-light-color: <light-color>;
    --<name>-light-contrast-color: <light-contrast-color>;
  */

	/** Prevalent color in the application */
	--primary-color: #9e3940;
	--primary-contrast-color: #ffffff;
	--primary-dark-color: #802e34;
	--primary-dark-contrast-color: #eeeeee;
	--primary-light-color: #bb444c;
	--primary-light-contrast-color: black;

	/** Color for backgrounds. */
	--background-color: #f7f7f7;
	--background-light-color: #fafafa;
	--background-dark-color: #d0d0d0;
	--background-contrast-color: #000000;
	--header-background-color: #40171a;

	/** Color for texts. */
	--text-color: #000000;
	--secondary-text-color: #b8b8b8;
	--button-text-primary-contrast-color: #777777;
	--text-contrast-color: #ffffff;
	--link-color: #06357a;

	/** Font size for texts. */
	--text-sm: 0.88rem;
	--text-base: 1rem;
	/* stylelint-disable-next-line number-max-precision */
	--text-md: 1.125rem;
	--text-lg: 1.25rem;
	--text-xl: 1.5rem;

	/** Color for buttons. */
	--disabled-button-color: #ededed;

	/** Color for borders. */
	--primary-border-color: #cfcfcf;

	/** Color for box shadow. */
	--box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07);
	--box-shadow-lg: 0px 16px 48px 0px rgba(0, 0, 0, 0.18);

	/** Color for status. */
	--error-color: red;

	/** Border radius. */
	--border-radius: 8px;
	--border-radius-lg: 16px;

	/** Color for divider. */
	--divider-color: #dddddd;
	--divider-color-light: #f4f4f4;

	/* A bit darker */
	--divider-color-dark: #b1afaf;

	/** Color for input. */
	--input-border-color: #cfcfcf;
	--input-background-dark-color: #ededed;
	--checkbox-accent-color: #0a58ca;

	/** Color for chips. */
	--chip-background-color: #f7f7f7;

	/** General spacing. */
	--container-max-width: 1440px;

	--active-notification-background-color: #dc3545;

	--header-height: 48px;
	--menu-height: 48px;
	--menu-item-background-color-on-hover: #fcf8f8;
}
