.menu .menu__link.mat-mdc-tab-link .mdc-tab__text-label {
	color: var(--button-text-primary-contrast-color);
}

.menu .menu__link.mdc-tab--active.mat-mdc-tab-link .mdc-tab__text-label {
	color: var(--text-color);
}

.menu__link--root .menu__link.mdc-tab--active .mdc-tab-indicator__content {
	opacity: 1;
}
