input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="date"],
input[type="number"],
input:not([type]),
textarea,
.input {
	width: 100%;
	padding: 10px;
	color: inherit;
	border-radius: 8px;
	border: 1px solid var(--input-border-color);
	background-color: var(--primary-contrast-color);
}

input[type="date"] {
	padding: 9px;
}

input:disabled {
	background-color: var(--input-background-dark-color);
}
