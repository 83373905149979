.divider {
	width: 100%;
	border: solid 1px var(--divider-color);
	margin-top: 30px;
	margin-bottom: 20px;
}

.divider_vertical {
	border: solid 1px var(--divider-color);
	margin: 20px;
}

.divider_light {
	border: solid 1px var(--divider-color-light);
}

.divider_dark {
	border: solid 1px var(--divider-color-dark);
}

.divider_no-margin {
	margin: 0;
}

.divider_margin-sm {
	margin-top: 15px;
	margin-bottom: 15px;
}
