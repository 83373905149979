mat-select {
	--mat-select-disabled-trigger-text-color: var(--text-color);
	width: 100%;
	padding-block: 6px;
	box-sizing: border-box;
	color: inherit;
	border-radius: 8px;
	border: 1px solid var(--input-border-color);
	background-color: var(--primary-contrast-color);
}

.mat-mdc-select-disabled {
	background-color: var(--input-background-dark-color);
	color: var(--text-color);
}

.mat-mdc-select-trigger {
	padding-inline: 8px;
}

.mat-mdc-optgroup-label {
	font-weight: 500;
}
