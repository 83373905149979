.container {
	display: flex;
	flex-direction: column;
	gap: 25px;
}

.container_wide-margin {
	max-width: var(--container-max-width);
	margin: 0 auto;
}
