.mat-button-toggle-appearance-standard.mat-button-toggle-checked {
	--mat-standard-button-toggle-selected-state-background-color: var(--primary-color);
	/* To override material styles. */
	/* stylelint-disable-next-line declaration-no-important */
	color: var(--text-contrast-color) !important;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
	--mat-standard-button-toggle-height: var(--mat-legacy-button-toggle-height);
}
