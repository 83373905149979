.form__row {
	display: flex;
	gap: 16px;
}

.form__field {
	width: 50%;
}

.form__field_small {
	width: 25%;
}

.form__field_tiny {
	width: 15%;
}

.form__section-header {
	color: var(--primary-color);
	font-size: var(--text-lg);
	margin-bottom: 20px;
}

.form__actions {
	display: flex;
	justify-content: flex-end;
	gap: 15px;
}
